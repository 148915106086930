
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  onBeforeMount,
  reactive,
  toRefs,
  ref,
  watchEffect,
  watch,
  nextTick,
} from "vue";
import { mainServer } from "@/apiServer/installServer";
export default defineComponent({
  name: "DemoView",
  components: {},
  props: {
    commonFromConfig: {
      type: Array,
      default: false,
    }, // true 为展示
    commonFromAnnotation: {
      type: String,
      default: "此处为表单填入的各个注意事项",
    }, // 表单注释信息
    isShowAnnotation: {
      type: Boolean,
      default: true,
    }, // 显示注释信息面板
  },
  setup(props, context) {
    const { proxy }: any = getCurrentInstance();
    const RefCommonFrom: any = ref(null);
    let data: any = reactive({
      annotationFalg: true,
      From: {},
      FromRules: {},
      annotation: "",
      annotationFalgContent: true,
    });
    watch(
      () => data.From,
      (userRecover, prevuserRecover) => {
        context.emit("exportFrom", data.From);
      },
      {
        deep: true,
      }
    );
    const FnExportFrom = function () {
      return new Promise((resolve, reject) => {
        RefCommonFrom.value.validate((valid: any) => {
          console.log("valid ---->", valid);
          if (valid) {
            resolve(data.From);
          } else {
            console.log("error submit!!");
            resolve(false);
          }
        });
      });
    };
    function FnReset() {
      data.From = {};
    }
    function FnSelectTrigger(value: any) {
      data.annotationFalgContent = !data.annotationFalgContent;
      console.log("FnSelectTrigger: value ---->", value);
      setTimeout(() => {
        data.annotation = value;
        data.annotationFalgContent = !data.annotationFalgContent;
      }, 200);
    }
    function FnChangeAnnotationFalg() {
      data.annotationFalg = !data.annotationFalg;
      data.annotationFalgContent = !data.annotationFalgContent;
      // nextTick(() => {
      //   if (data.annotationFalgContent) {
      //     data.annotationFalgContent = false;
      //   } else {
      //     setTimeout(() => {
      //       data.annotationFalgContent = true;
      //     }, 500);
      //   }
      // });
    }

    onBeforeMount(() => {
      for (const iterator of props.commonFromConfig) {
        for (const subIterator of iterator as any) {
          data.From[subIterator.BindingValues] = "";
          data.FromRules[subIterator.BindingValues] = subIterator.rules;
        }
      }
    });
    onMounted(() => {
      data.annotation = props.commonFromAnnotation;
    });
    return {
      ...toRefs(data),
      proxy,
      FnExportFrom,
      RefCommonFrom,
      FnSelectTrigger,
      FnChangeAnnotationFalg,
      FnReset,
    };
  },
});
