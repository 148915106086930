import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bd1b5bee"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "CommonFrom",
  class: "layOut_center_center"
}
const _hoisted_2 = { class: "CommonFrom_class_1 layOut_start_center" }
const _hoisted_3 = { class: "CommonFrom_class_6" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  class: "CommonFrom_class_4 layOut_end_center",
  style: {"flex-shrink":"0"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_slider = _resolveComponent("el-slider")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_el_scrollbar, { style: {"height":"100%"} }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form, {
              model: _ctx.From,
              ref: "RefCommonFrom",
              rules: _ctx.FromRules,
              "label-width": "150px"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.commonFromConfig, (item, index) => {
                  return (_openBlock(), _createBlock(_component_el_row, {
                    gutter: 20,
                    key: item + index,
                    style: {"width":"calc(100% - 2px)"}
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item, (subItem, subIndex) => {
                        return (_openBlock(), _createBlock(_component_el_col, {
                          span: subItem.span,
                          offset: 0,
                          key: subItem + subIndex
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_form_item, {
                              label: `${subItem.BindingLabels}:`,
                              size: "normal",
                              prop: subItem.BindingValues
                            }, {
                              default: _withCtx(() => [
                                (subItem.type === 'input')
                                  ? (_openBlock(), _createBlock(_component_el_input, {
                                      key: 0,
                                      style: {"width":"100%"},
                                      modelValue: _ctx.From[subItem.BindingValues],
                                      "onUpdate:modelValue": ($event: any) => ((_ctx.From[subItem.BindingValues]) = $event),
                                      placeholder: subItem.placeholder,
                                      maxlength: subItem.NumberOfWords,
                                      "show-word-limit": "",
                                      onClick: ($event: any) => (_ctx.FnSelectTrigger(subItem.annotation)),
                                      onBlur: ($event: any) => ((subItem.FnBlur) ? subItem.FnBlur($event , subItem) : '')
                                    }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder", "maxlength", "onClick", "onBlur"]))
                                  : _createCommentVNode("", true),
                                (subItem.type === 'textarea')
                                  ? (_openBlock(), _createBlock(_component_el_input, {
                                      key: 1,
                                      modelValue: _ctx.From[subItem.BindingValues],
                                      "onUpdate:modelValue": ($event: any) => ((_ctx.From[subItem.BindingValues]) = $event),
                                      rows: subItem.row,
                                      type: "textarea",
                                      maxlength: subItem.NumberOfWords,
                                      "show-word-limit": "",
                                      placeholder: subItem.placeholder,
                                      onClick: ($event: any) => (_ctx.FnSelectTrigger(subItem.annotation))
                                    }, null, 8, ["modelValue", "onUpdate:modelValue", "rows", "maxlength", "placeholder", "onClick"]))
                                  : _createCommentVNode("", true),
                                _createElementVNode("div", {
                                  onClick: ($event: any) => (_ctx.FnSelectTrigger(subItem.annotation))
                                }, [
                                  (subItem.type === 'datetimerange')
                                    ? (_openBlock(), _createBlock(_component_el_date_picker, {
                                        key: 0,
                                        modelValue: _ctx.From[subItem.BindingValues],
                                        "onUpdate:modelValue": ($event: any) => ((_ctx.From[subItem.BindingValues]) = $event),
                                        type: "datetimerange",
                                        "value-format": subItem.valueFormat,
                                        "range-separator": "至",
                                        "start-placeholder": subItem.startPlaceholder,
                                        "end-placeholder": subItem.endPlaceholder
                                      }, null, 8, ["modelValue", "onUpdate:modelValue", "value-format", "start-placeholder", "end-placeholder"]))
                                    : _createCommentVNode("", true)
                                ], 8, _hoisted_4),
                                (subItem.type === 'dropDownRadio')
                                  ? (_openBlock(), _createBlock(_component_el_select, {
                                      key: 2,
                                      modelValue: _ctx.From[subItem.BindingValues],
                                      "onUpdate:modelValue": ($event: any) => ((_ctx.From[subItem.BindingValues]) = $event),
                                      placeholder: subItem.placeholder,
                                      "value-key": "value",
                                      style: {"width":"100%"},
                                      onClick: ($event: any) => (_ctx.FnSelectTrigger(subItem.annotation))
                                    }, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subItem.options, (optionsItem, optionsIndex) => {
                                          return (_openBlock(), _createBlock(_component_el_option, {
                                            key: optionsItem + optionsIndex,
                                            label: optionsItem.label,
                                            value: optionsItem.value
                                          }, null, 8, ["label", "value"]))
                                        }), 128))
                                      ]),
                                      _: 2
                                    }, 1032, ["modelValue", "onUpdate:modelValue", "placeholder", "onClick"]))
                                  : _createCommentVNode("", true),
                                (subItem.type === 'dropDownMultiSelect')
                                  ? (_openBlock(), _createBlock(_component_el_select, {
                                      key: 3,
                                      modelValue: _ctx.From[subItem.BindingValues],
                                      "onUpdate:modelValue": ($event: any) => ((_ctx.From[subItem.BindingValues]) = $event),
                                      multiple: "",
                                      placeholder: subItem.placeholder,
                                      style: {"width":"100%"},
                                      onClick: ($event: any) => (_ctx.FnSelectTrigger(subItem.annotation))
                                    }, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subItem.options, (optionsItem, optionsIndex) => {
                                          return (_openBlock(), _createBlock(_component_el_option, {
                                            key: optionsItem + optionsIndex,
                                            label: optionsItem.label,
                                            value: optionsItem.value
                                          }, null, 8, ["label", "value"]))
                                        }), 128))
                                      ]),
                                      _: 2
                                    }, 1032, ["modelValue", "onUpdate:modelValue", "placeholder", "onClick"]))
                                  : _createCommentVNode("", true),
                                (subItem.type === 'inputPrepend')
                                  ? (_openBlock(), _createBlock(_component_el_input, {
                                      key: 4,
                                      modelValue: _ctx.From[subItem.BindingValues],
                                      "onUpdate:modelValue": ($event: any) => ((_ctx.From[subItem.BindingValues]) = $event),
                                      placeholder: subItem.placeholder,
                                      onClick: ($event: any) => (_ctx.FnSelectTrigger(subItem.annotation))
                                    }, {
                                      prepend: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(subItem.prepend), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["modelValue", "onUpdate:modelValue", "placeholder", "onClick"]))
                                  : _createCommentVNode("", true),
                                (subItem.type === 'inputAppend')
                                  ? (_openBlock(), _createBlock(_component_el_input, {
                                      key: 5,
                                      modelValue: _ctx.From[subItem.BindingValues],
                                      "onUpdate:modelValue": ($event: any) => ((_ctx.From[subItem.BindingValues]) = $event),
                                      placeholder: subItem.placeholder,
                                      onClick: ($event: any) => (_ctx.FnSelectTrigger(subItem.annotation))
                                    }, {
                                      append: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(subItem.append), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["modelValue", "onUpdate:modelValue", "placeholder", "onClick"]))
                                  : _createCommentVNode("", true),
                                (subItem.type === 'slider')
                                  ? (_openBlock(), _createElementBlock("div", {
                                      key: 6,
                                      class: "CommonFrom_class_7 layOut_around_center",
                                      onClick: ($event: any) => (_ctx.FnSelectTrigger(subItem.annotation))
                                    }, [
                                      _createVNode(_component_el_slider, {
                                        style: {"width":"70%"},
                                        modelValue: _ctx.From[subItem.BindingValues],
                                        "onUpdate:modelValue": ($event: any) => ((_ctx.From[subItem.BindingValues]) = $event),
                                        step: subItem.step,
                                        marks: subItem.marks,
                                        onClick: ($event: any) => (_ctx.FnSelectTrigger(subItem.annotation)),
                                        "show-stops": ""
                                      }, null, 8, ["modelValue", "onUpdate:modelValue", "step", "marks", "onClick"]),
                                      _createVNode(_component_el_input, {
                                        modelValue: _ctx.From[subItem.BindingValues],
                                        "onUpdate:modelValue": ($event: any) => ((_ctx.From[subItem.BindingValues]) = $event),
                                        placeholder: "",
                                        style: {"width":"15%","margin":"0px 20px"},
                                        size: "small",
                                        disabled: "",
                                        onClick: ($event: any) => (_ctx.FnSelectTrigger(subItem.annotation))
                                      }, {
                                        append: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(subItem.unitName), 1)
                                        ]),
                                        _: 2
                                      }, 1032, ["modelValue", "onUpdate:modelValue", "onClick"])
                                    ], 8, _hoisted_5))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1032, ["label", "prop"])
                          ]),
                          _: 2
                        }, 1032, ["span"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }, 8, ["model", "rules"])
          ]),
          _: 1
        })
      ])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", {
        class: "CommonFrom_class_5",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.FnChangeAnnotationFalg && _ctx.FnChangeAnnotationFalg(...args)))
      })
    ], 512), [
      [_vShow, _ctx.isShowAnnotation]
    ]),
    _withDirectives(_createElementVNode("div", {
      class: "CommonFrom_class_2",
      style: _normalizeStyle([!_ctx.annotationFalg ? 'width: 0px' : ''])
    }, [
      _createVNode(_Transition, { name: "el-fade-in-linear" }, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("div", { class: "CommonFrom_class_3" }, _toDisplayString(_ctx.annotation), 513), [
            [_vShow, _ctx.annotationFalgContent]
          ])
        ]),
        _: 1
      })
    ], 4), [
      [_vShow, _ctx.isShowAnnotation]
    ])
  ]))
}